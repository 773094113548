import OrderService from '@/services/Order/OrderService'
import { OrderType } from '@/types/OrderType'
import { flashStore } from '@/utils/Store'
import * as Sentry from '@sentry/react'
import { AxiosResponse } from 'axios'
import { Edit, Plus, X } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, ListGroup, Spinner } from 'react-bootstrap'

interface Product {
  name: string
  quantity: number
  price: number
}

interface ApiResponse {
  response: AxiosResponse
  violations: Violation[]
}

interface Violation {
  propertyPath: string
  message: string
  code: string
}

type Props = {
  order: OrderType | undefined
}

export const ProductDetailCard: React.FC<Props> = ({ order }) => {
  const [onSubmitLoading, setOnSubmitLoading] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [data, setData] = useState<OrderType>(
    order || ({ products: [] } as unknown as OrderType)
  )
  const flash = flashStore()

  useEffect(() => {
    if (order) setData(order)
  }, [order])

  const handleProductChange = (index: number, key: keyof Product, value: string | number) => {
    const updatedProducts = [...(data.products || [])]
    updatedProducts[index] = { ...updatedProducts[index], [key]: value }
    setData({ ...data, products: updatedProducts })
  }

  const handleAddProduct = () => {
    const updatedProducts = [...(data.products || [])]
    updatedProducts.push({ name: '', price: 1, quantity: 1 })
    setData({ ...data, products: updatedProducts })
  }

  const handleRemoveProduct = (index: number) => {
    const updatedProducts = [...(data.products || [])]
    updatedProducts.splice(index, 1)
    setData({ ...data, products: updatedProducts })
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    setOnSubmitLoading(true)
    if (data?.id !== undefined) {
      OrderService.update(data.id, { products: data.products })
        .then(() => {
          flash.setMessage('success', 'Les produits ont bien été modifiés !')
        })
        .catch((error: ApiResponse) => {
          if (error?.response?.data?.violations?.length > 0) {
            flash.setMessageErrorValidator()
          } else {
            flash.setMessageErrorForm()
            Sentry.captureException(error)
          }
        })
        .finally(() => {
          setIsEditing(false)
          setOnSubmitLoading(false)
        })
    }
  }

  return (
    <Card className='mb-4'>
      <Card.Header className='d-flex justify-content-between align-items-center'>
        <h5 className='mt-2'>Produits à livrer</h5>
        <Edit className='cursor-pointer' onClick={() => setIsEditing(true)} />
      </Card.Header>
      <Card.Body className='p-1'>
        {!isEditing ? (
          <ListGroup as='ul' variant='flush'>
            {data.products && data.products.length > 0 ? (
              data?.products.map((product, index) => (
                <ListGroup.Item as='li' key={index} className='py-3'>
                  {product.quantity} x {product.name}
                </ListGroup.Item>
              ))
            ) : (
              <></>
            )}
          </ListGroup>
        ) : (
          <Form onSubmit={handleSubmit}>
            <Col>
              <ListGroup as='ul' variant='flush'>
                {data.products?.map((product, index) => (
                  <ListGroup.Item
                    as='li'
                    key={index}
                    className='py-3 px-0 d-flex align-items-center'
                  >
                    <Form.Control
                      type='text'
                      value={product.quantity}
                      className='me-2 p-0 text-center'
                      onChange={(e) =>
                        handleProductChange(index, 'quantity', parseInt(e.target.value, 10))
                      }
                      style={{ width: '60px' }}
                    />
                    <Form.Control
                      type='text'
                      value={product.name}
                      onChange={(e) => handleProductChange(index, 'name', e.target.value)}
                      className='me-2'
                      style={{ width: '100%' }}
                    />
                    <Button
                      variant='transparent'
                      onClick={() => handleRemoveProduct(index)}
                      className='ms-2'
                    >
                      <X />
                    </Button>
                  </ListGroup.Item>
                ))}
                <Button
                  variant='transparent text-success ps-0 mt-2 w-50'
                  onClick={handleAddProduct}
                >
                  <Plus size={16} /> Ajouter un nouveau produit
                </Button>
              </ListGroup>
            </Col>
            <div className='d-flex justify-content-end mt-3 pb-3 pe-3'>
              <Button
                variant='secondary'
                type='button'
                className='me-3'
                onClick={() => setIsEditing(false)}
              >
                Annuler
              </Button>
              <Button variant='dark' type='submit'>
                {onSubmitLoading ? <Spinner size='sm' /> : 'Enregistrer'}
              </Button>
            </div>
          </Form>
        )}
      </Card.Body>
    </Card>
  )
}
