import ClientService from '@/services/Client/ClientService'
import { ClientType } from '@/types/ClientType'
import { getMessageErrorSubmit } from '@/utils/Functions'
import { flashStore } from '@/utils/Store'
import * as Sentry from '@sentry/react'
import { AxiosResponse } from 'axios'
import { Edit } from 'lucide-react'
import React, { useState } from 'react'
import { Button, Card, Col, Form, Image, ListGroup, Row, Spinner } from 'react-bootstrap'

interface ApiResponse {
  response: AxiosResponse
  violations: Violation[]
}

interface Violation {
  propertyPath: string
  message: string
  code: string
}

type Props = {
  client: ClientType | undefined
}

export const ClientDetailCard: React.FC<Props> = ({ client }: Props) => {
  /* States
   *******************************************************************************************/
  const [onSubmitLoading, setOnSubmitLoading] = React.useState<boolean>(false)
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [messageErrorInput, setMessageErrorInput] = React.useState()
  const [data, setData] = useState<ClientType | undefined>(client)
  const flash = flashStore()

  /* UseEffect
   *******************************************************************************************/
  React.useEffect(() => {
    setData(client)
  }, [client])

  /* Functions
   *******************************************************************************************/
  // Function to handle input changes in the form fields
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setData({ ...data, [name]: value } as ClientType)
  }

  // Function to handle form submission
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setOnSubmitLoading(true)
    if (data?.id !== undefined) {
      ClientService.update(data.id, data)
        .then(() => {
          flash.setMessage('success', 'Les informations du client a bien été modifié !')
        })
        .catch((error: ApiResponse) => {
          if (error?.response?.data?.violations?.length > 0) {
            flash.setMessageErrorValidator()
            setMessageErrorInput(error.response.data.violations)
          } else {
            flash.setMessageErrorForm()
            Sentry.captureException(error)
          }
        })
        .finally(() => {
          setIsEditing(false)
          setOnSubmitLoading(false)
        })
    }
  }

  /* Return
   *******************************************************************************************/
  return (
    <Card className='mb-4'>
      <Card.Header className='d-flex justify-content-between align-items-center'>
        <h5 className='mt-2'>Client</h5>
        <Edit className='cursor-pointer' onClick={() => setIsEditing(true)} />
      </Card.Header>
      <Card.Body className='p-3'>
        {!isEditing ? (
          <Row>
            <Col lg={3} className='text-center'>
              <Image
                style={{ width: '80px' }}
                src={'/img/avatar-default.png'}
                alt={'client'}
                className='mt-3'
              />
            </Col>
            <Col lg={9}>
              <ListGroup as='ul' variant='flush'>
                <ListGroup.Item as='li'>
                  <i className='ri-account-box-line ri-lg align-middle text-muted me-1'></i>{' '}
                  {data?.firstname} {data?.lastname}
                </ListGroup.Item>
                <ListGroup.Item as='li'>
                  <i className='ri-mail-line ri-lg align-middle text-muted me-1'></i>{' '}
                  {data?.email}
                </ListGroup.Item>
                <ListGroup.Item as='li'>
                  <i className='ri-smartphone-line ri-lg align-middle text-muted me-1'></i>{' '}
                  {data?.phone}
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        ) : (
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId='formFirstName'>
              <Form.Label>Prénom</Form.Label>
              <Form.Control
                type='text'
                name='firstname'
                className='mb-3'
                value={data?.firstname || ''}
                onChange={handleInputChange}
              />
              {messageErrorInput && (
                <small className='text-danger'>
                  {getMessageErrorSubmit(messageErrorInput, 'firstname')}
                </small>
              )}
            </Form.Group>
            <Form.Group controlId='formLastName'>
              <Form.Label>Nom</Form.Label>
              <Form.Control
                type='text'
                name='lastname'
                className='mb-3'
                value={data?.lastname || ''}
                onChange={handleInputChange}
              />
              {messageErrorInput && (
                <small className='text-danger'>
                  {getMessageErrorSubmit(messageErrorInput, 'lastname')}
                </small>
              )}
            </Form.Group>
            <Form.Group controlId='formEmail'>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type='email'
                name='email'
                className='mb-3'
                value={data?.email || ''}
                onChange={handleInputChange}
              />
              {messageErrorInput && (
                <small className='text-danger'>
                  {getMessageErrorSubmit(messageErrorInput, 'email')}
                </small>
              )}
            </Form.Group>
            <Form.Group controlId='formPhone'>
              <Form.Label>Téléphone</Form.Label>
              <Form.Control
                type='text'
                name='phone'
                className='mb-3'
                value={data?.phone || ''}
                onChange={handleInputChange}
              />
              {messageErrorInput && (
                <small className='text-danger'>
                  {getMessageErrorSubmit(messageErrorInput, 'phone')}
                </small>
              )}
            </Form.Group>
            <div className='d-flex justify-content-end mt-3'>
              <Button
                variant='secondary'
                type='submit'
                className='me-3'
                onClick={() => setIsEditing(false)}
              >
                Annuler
              </Button>
              <Button variant='dark' type='submit'>
                {onSubmitLoading ? <Spinner size='sm' /> : 'Enregistrer'}
              </Button>
            </div>
          </Form>
        )}
      </Card.Body>
    </Card>
  )
}
