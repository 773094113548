/* eslint-disable @typescript-eslint/no-explicit-any */
import { ButtonLinkBack } from '@/components/lib/ButtonLinkBack'
import { Title } from '@/components/lib/Title'
import BookingSlotService from '@/services/BookingSlot/BookingSlotService'
import OrderService from '@/services/Order/OrderService'
import { OrderType } from '@/types/OrderType'
import { generateMultiorderCode, getMessageErrorSubmit } from '@/utils/Functions'
import { flashStore } from '@/utils/Store'
import * as Sentry from '@sentry/react'
import { AxiosResponse } from 'axios'
import { Plus, X } from 'lucide-react'
import React from 'react'
import { Button, Card, Col, Form, ListGroup, Row, Spinner } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

interface ApiResponse {
  response: AxiosResponse
  violations: Violation[]
}

interface Violation {
  propertyPath: string
  message: string
  code: string
}

export const OrderDuplicate = () => {
  /* States / Hooks
   *******************************************************************************************/
  const param = useParams()
  const { register, handleSubmit } = useForm<OrderType>()
  const [data, setData] = React.useState<OrderType>()
  const [idOrder, setIdOrder] = React.useState<number>()
  const [onSubmitLoading, setOnSubmitLoading] = React.useState<boolean>(false)
  const [temperatureZone, setTemperatureZone] = React.useState<any[]>([])
  const [messageErrorInput, setMessageErrorInput] = React.useState()
  const navigate = useNavigate()
  // const [saveData, setSaveData] = React.useState<OrderType>();
  const flash = flashStore()

  /* useEffect
   *******************************************************************************************/
  React.useEffect(() => {
    getData(param.id_order)
    setIdOrder(Number(param.id_order))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param])

  /* Function / Events
   *******************************************************************************************/
  const getData = async (id_order: string | undefined) => {
    OrderService.getOrderByIdForAdmin(Number(id_order))
      .then((response: AxiosResponse) => {
        const order = response.data
        console.log(order)
        setData(order)
        BookingSlotService.getBookingSlotPerPage(1, 200).then((response: AxiosResponse) => {
          const tempZone = response.data['hydra:member']
            .map((tz: any) => {
              if (order?.company?.id === tz.company.id) {
                return {
                  bookingSlot: tz.id,
                  location: tz.slot.temperatureZone.locker.location,
                  temperatureZone: tz.slot.temperatureZone.name,
                }
              }
              return null
            })
            .filter((item: any) => item !== null)
          setTemperatureZone(tempZone)
        })
      })
      .catch((error: ApiResponse) => {
        if (error?.response?.data?.violations?.length > 0) {
          flash.setMessageErrorValidator()
          setMessageErrorInput(error.response.data.violations)
        } else {
          flash.setMessageErrorForm()
          Sentry.captureException(error)
        }
      })
  }

  const onSubmit = (order: OrderType) => {
    setOnSubmitLoading(true)

    const _multiOrderCode = data?.multiOrderCode || generateMultiorderCode()
    const _hold = Number(data?.hold)

    const newData = {
      barcode: order?.barcode,
      receiveCode: data?.receiveCode,
      multiOrderCode: _multiOrderCode.toString(),
      bookingSlot: '/api/booking_slots/' + order?.bookingSlot,
      products: data?.products || [],
      externalOrderId: data?.externalOrderId,
      hold: _hold,
      ageRestriction: order?.ageRestriction ? 18 : undefined,
      client: {
        email: data?.client?.email || '',
        firstname: data?.client?.firstname || '',
        lastname: data?.client?.lastname || '',
        phone: data?.client?.phone || '',
      },
    }

    OrderService.create(newData)
      .then((e: AxiosResponse) => {
        flash.setMessage('success', 'Commande dupliquée !')
        navigate('/livraisons/detail/' + e.data.id)
      })
      .catch((error: ApiResponse) => {
        if (error?.response?.data?.violations?.length > 0) {
          flash.setMessageErrorValidator()
          setMessageErrorInput(error.response.data.violations)
        } else {
          flash.setMessageErrorForm()
          Sentry.captureException(error)
        }
      })
      .finally(() => {
        setOnSubmitLoading(false)
      })

    if (data?.multiOrderCode === undefined) {
      if (idOrder !== undefined) {
        OrderService.update(idOrder, {
          multiOrderCode: _multiOrderCode.toString(),
        }).catch((error: ApiResponse) => {
          if (error?.response?.data?.violations?.length > 0) {
            flash.setMessageErrorValidator()
            setMessageErrorInput(error.response.data.violations)
          } else {
            flash.setMessageErrorForm()
            Sentry.captureException(error)
          }
        })
      }
    }
  }

  /* Render
   *******************************************************************************************/
  return (
    <>
      <Row>
        <Col>
          <Title name={'Livraison / duplication'} />
        </Col>
        <Col>
          <ButtonLinkBack />
        </Col>
      </Row>
      <Row className='mt-4'>
        <Col className='col-xl-6 col-lg-10 col-md-12 mx-auto'>
          <Card className='mb-4'>
            <Card.Header>
              <h5 className='mt-2'>Modifier les informations de la nouvelle commande</h5>
            </Card.Header>
            <Card.Body className='p-5'>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group as={Row} className='mb-3'>
                  <Form.Label column sm={3}>
                    Barcode <span className='text-danger'>*</span>
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      type='text'
                      defaultValue={data?.barcode}
                      {...register('barcode', { required: true })}
                    />
                    {messageErrorInput && (
                      <small className='text-danger'>
                        {getMessageErrorSubmit(messageErrorInput, 'barcode')}
                      </small>
                    )}
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className='mb-3'>
                  <Form.Label column sm={3}>
                    Zone de température <span className='text-danger'>*</span>
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Select
                      aria-label='Liste des zones de température'
                      className='form-control'
                      required
                      {...register('bookingSlot', { required: true })}
                    >
                      <option value={''}>-- Sélectionnez la zone de température --</option>
                      {temperatureZone &&
                        temperatureZone.map((tz: any) => (
                          <option key={tz?.bookingSlot} value={tz?.bookingSlot}>
                            {tz?.location} - {tz?.temperatureZone}
                          </option>
                        ))}
                    </Form.Select>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className='mb-3'>
                  <Form.Label column sm={3}>
                    Hold time
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      placeholder='2880'
                      type='text'
                      defaultValue={data?.hold}
                      required
                      {...register('hold')}
                    />
                    <small className='text-muted '>Exprimer en minute</small>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className='mb-3'>
                  <Form.Label column sm={3}>
                    Produits <span className='text-danger'></span>
                  </Form.Label>
                  <Col sm={9}>
                    <ListGroup as='ul' variant='flush'>
                      {data?.products !== undefined &&
                        data?.products?.length > 0 &&
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        data?.products.map((product: any, index: number) => (
                          <ListGroup.Item
                            as='li'
                            key={index}
                            className='py-3 px-0 d-flex align-items-center'
                          >
                            <Form.Control
                              type='text'
                              defaultValue={product.quantity}
                              onChange={(e) => {
                                const updatedProducts = data?.products
                                  ? [...data.products]
                                  : []
                                updatedProducts[index].quantity = parseInt(e.target.value, 10)
                                setData({ ...data!, products: updatedProducts })
                              }}
                              className='me-2'
                              style={{ display: 'inline-block', width: '60px' }}
                            />
                            <Form.Control
                              type='text'
                              defaultValue={product.name}
                              onChange={(e) => {
                                const updatedProducts = data?.products
                                  ? [...data.products]
                                  : []
                                updatedProducts[index].name = e.target.value
                                setData({ ...data, products: updatedProducts })
                              }}
                              className='me-2'
                              style={{ display: 'inline-block', width: '100%' }}
                            />
                            <Form.Control
                              type='hidden'
                              defaultValue={1}
                              className='me-2'
                              style={{ display: 'inline-block', width: 'auto' }}
                            />
                            <Button
                              variant='transparent'
                              onClick={() => {
                                const updatedProducts = data?.products
                                  ? [...data.products]
                                  : []
                                updatedProducts.splice(index, 1)
                                setData({ ...data!, products: updatedProducts })
                              }}
                              className='ms-2'
                            >
                              <X />
                            </Button>
                          </ListGroup.Item>
                        ))}
                      <Button
                        variant='transparent text-success ps-0 mt-2 w-50'
                        onClick={() => {
                          const updatedProducts = data?.products ? [...data.products] : []
                          updatedProducts.push({ name: '', price: 1, quantity: 1 })
                          setData({ ...data!, products: updatedProducts } as OrderType)
                        }}
                      >
                        <Plus size={16} /> Ajouter un nouveau produit
                      </Button>
                    </ListGroup>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className='mb-3'>
                  <Form.Label column sm={3}>
                    Commande contenant de l'alcool ?
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Check
                      type='checkbox'
                      label='Oui'
                      defaultChecked={!!data?.ageRestriction}
                      {...register('ageRestriction')}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className='mb-3 float-end'>
                  <Col>
                    <Button type='submit' variant='dark'>
                      {onSubmitLoading ? <Spinner size={'sm'} /> : 'Enregistrer'}
                    </Button>
                  </Col>
                </Form.Group>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  )
}
