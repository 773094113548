/* eslint-disable react-hooks/exhaustive-deps */
import { EmptyData } from '@/components/lib/EmptyData'
import { Loading } from '@/components/lib/Loading'
import AuthService from '@/services/Auth/AuthService'
import OrderService from '@/services/Order/OrderService'
import { OrderType } from '@/types/OrderType'
import { setDateTime, setOrderStatus } from '@/utils/Functions'
import { flashStore } from '@/utils/Store'
import * as Sentry from '@sentry/react'
import { AxiosError, AxiosResponse } from 'axios'
import React from 'react'
import { Card, Col, Form, Row, Spinner, Table } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import Swal, { SweetAlertResult } from 'sweetalert2'

interface ApiResponse {
  response: AxiosResponse
  violations: Violation[]
}

interface Violation {
  propertyPath: string
  message: string
  code: string
}

export const OrderListTable = () => {
  /* Hooks
   *******************************************************************************************/
  const flash = flashStore()
  const navigate = useNavigate()

  /* States
   *******************************************************************************************/
  const [data, setData] = React.useState<OrderType[]>([])
  const [loading, setLoading] = React.useState<boolean>(true)
  const [loadSpinner, setLoadSpinner] = React.useState<boolean>(false)
  const [nextPage, setNextPage] = React.useState<string | undefined>()
  const [prevPage, setPrevPage] = React.useState<string | undefined>()
  const [currentPage, setCurrentPage] = React.useState<number>(1)
  const [perPage, setPerPage] = React.useState<number>(10)
  const [totalItems, setTotalItems] = React.useState<number>(0)
  const [barcode, setBarcode] = React.useState<string>('')
  const [status, setStatus] = React.useState<string>('')

  /* Constants
   *******************************************************************************************/
  const perPageOptions = [10, 20, 50, 100]
  const totalPages = Math.ceil(totalItems / perPage)

  // Extract query parameters from the URL
  const queryParams = new URLSearchParams(window.location.search)
  const pageParam = Number(queryParams.get('page'))
  const perPageParam = Number(queryParams.get('per_page'))
  const barcodeParam = queryParams.get('barcode')?.toString()
  const statusParam = queryParams.get('status')?.toString()

  /* useEffect
   *******************************************************************************************/
  React.useEffect(() => {
    if (pageParam) {
      setCurrentPage(pageParam)
    }
    if (perPageParam) {
      setPerPage(perPageParam)
    }
    if (barcodeParam) {
      setBarcode(barcodeParam)
    }
    if (statusParam) {
      setStatus(statusParam)
    }
    getData()
  }, [pageParam, perPageParam, barcodeParam, statusParam])

  /* Functions
   *******************************************************************************************/
  const getData = () => {
    const _currentPage = pageParam ? pageParam : currentPage
    const _perPage = perPageParam ? perPageParam : perPage
    const _barcode = barcodeParam ? barcodeParam : barcode
    const _status = statusParam ? statusParam : status

    OrderService.getOrders(_currentPage, _perPage, _barcode, _status)
      .then((response: AxiosResponse) => {
        setData(response.data['hydra:member'])
        setNextPage(response.data['hydra:view']['hydra:next'])
        setPrevPage(response.data['hydra:view']['hydra:previous'])
        setTotalItems(response.data['hydra:totalItems'])
      })
      .catch((error: AxiosError) => {
        AuthService.refreshToken()
        Sentry.captureException(error)
      })
      .finally(() => {
        setLoading(false)
        setLoadSpinner(false)
      })
  }

  const getPages = (currentPage: number, totalPages: number) => {
    const maxPagesToShow = 5
    let pages = []

    if (totalPages <= maxPagesToShow) {
      pages = [...Array(totalPages).keys()].map((x) => x + 1)
    } else {
      if (currentPage <= 3) {
        pages = [1, 2, 3, 4, '...', totalPages]
      } else if (currentPage > totalPages - 3) {
        pages = [1, '...', totalPages - 3, totalPages - 2, totalPages - 1, totalPages]
      } else {
        pages = [1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages]
      }
    }

    return pages
  }

  const goToPage = (page: number) => {
    setLoadSpinner(true)
    setCurrentPage(page)
    if (barcode) {
      navigate(`?page=${page}&per_page=${perPage}&barcode=${barcode}`)
    }

    if (status) {
      navigate(`?page=${page}&per_page=${perPage}&status=${status}`)
    }

    if (barcode && status) {
      navigate(`?page=${page}&per_page=${perPage}&barcode=${barcode}&status=${status}`)
    }

    if (!barcode && !status) {
      navigate(`?page=${page}&per_page=${perPage}`)
    }
  }

  const handlePerPageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newPerPage = Number(event.target.value)
    setCurrentPage(1)
    setLoadSpinner(true)
    setPerPage(newPerPage)
    if (barcode) {
      navigate(`?page=1&per_page=${newPerPage}&barcode=${barcode}`)
    }

    if (status) {
      navigate(`?page=1&per_page=${newPerPage}&status=${status}`)
    }

    if (barcode && status) {
      navigate(`?page=1&per_page=${newPerPage}&barcode=${barcode}&status=${status}`)
    }

    if (!barcode && !status) {
      navigate(`?page=1&per_page=${newPerPage}`)
    }
  }

  const handleUpdateStatus = (id_order: number, status: string) => {
    Swal.fire({
      title: 'Confirmation !',
      text: 'Voulez-vous vraiment modifier le statut de la commande #' + id_order + ' ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, je confirme',
      cancelButtonText: 'Non',
    }).then((result: SweetAlertResult) => {
      if (result.isConfirmed) {
        OrderService.updateStatus(id_order, status)
          .then(() => {
            flash.setMessage('success', 'Commande #' + id_order + ' modifiée !')
            getData()
          })
          .catch((error: ApiResponse) => {
            flash.setMessageErrorForm()
            Sentry.captureException(error)
          })
      }
    })
  }

  const handleDelete = (id: number) => {
    Swal.fire({
      title: 'Confirmation !',
      text: 'Voulez-vous vraiment supprimer la commande #' + id + ' ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, je confirme',
      cancelButtonText: 'Non',
    }).then((result: SweetAlertResult) => {
      if (result.isConfirmed) {
        OrderService._delete(id)
          .then(() => {
            flash.setMessage('success', 'Commande supprimée !')
            getData()
          })
          .catch((e: ApiResponse) => {
            if (e?.response?.status == 403) {
              flash.setMessage('error', e?.response?.data['hydra:description'])
            }
            Sentry.captureException(e)
          })
      }
    })
  }

  const handleSendNotification = (id_order: number, key: string) => {
    let nameKey: string = "l'email"
    if (key === 'sms') {
      nameKey = 'le sms'
    }

    Swal.fire({
      title: 'Confirmation !',
      text: 'Voulez-vous vraiment renvoyer ' + nameKey + ' de la commande #' + id_order + ' ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, je confirme',
      cancelButtonText: 'Non',
    }).then((result: SweetAlertResult) => {
      if (result.isConfirmed) {
        if (key === 'sms') {
          sendNotificationOrder(id_order, 'sms')
        } else {
          sendNotificationOrder(id_order, 'mail')
        }
      }
    })
  }

  const sendNotificationOrder = (id_order: number, $provider: string) => {
    OrderService.resendNotification(id_order, $provider)
      .then(() => {
        flash.setMessage('success', 'Votre demande a bien été effectuée !')
      })
      .catch((error: ApiResponse) => {
        flash.setMessageErrorForm()
        Sentry.captureException(error)
      })
  }

  const handleBarcodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBarcode(event.target.value)
  }

  const handleBarcodeSearch = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      setLoadSpinner(true)
      setCurrentPage(1)
      if (barcode) {
        navigate(`?page=1&per_page=${perPage}&barcode=${barcode}`)
      }

      if (status) {
        navigate(`?page=1&per_page=${perPage}&status=${status}`)
      }

      if (barcode && status) {
        navigate(`?page=1&per_page=${perPage}&barcode=${barcode}&status=${status}`)
      }

      if (!barcode && !status) {
        navigate(`?page=1&per_page=${perPage}`)
      }
    }
  }

  const handleStatusChange = (status: string) => {
    setStatus(status)
    setCurrentPage(1)
    setLoadSpinner(true)
    if (barcode) {
      navigate(`?page=1&per_page=${perPage}&barcode=${barcode}`)
    }

    if (status) {
      navigate(`?page=1&per_page=${perPage}&status=${status}`)
    }

    if (barcode && status) {
      navigate(`?page=1&per_page=${perPage}&barcode=${barcode}&status=${status}`)
    }

    if (!barcode && !status) {
      navigate(`?page=1&per_page=${perPage}`)
    }
  }

  /* PaginationComponent Component
   *******************************************************************************************/
  const PaginationComponent = () => {
    const pages = getPages(currentPage, totalPages)

    return (
      <div className='ms-auto p-3'>
        <nav aria-label='Page navigation example'>
          <ul className='pagination'>
            <li className='page-item'>
              <button
                className='page-link'
                disabled={!prevPage}
                onClick={() => goToPage(currentPage - 1)}
              >
                <span aria-hidden='true'>&laquo;</span>
              </button>
            </li>
            {pages.map((page, index) => (
              <li key={index} className={`page-item ${currentPage === page ? 'active' : ''}`}>
                {page === '...' ? (
                  <span className='page-link'>...</span>
                ) : (
                  <button className='page-link' onClick={() => goToPage(Number(page))}>
                    {page}
                  </button>
                )}
              </li>
            ))}
            <li className='page-item'>
              <button
                className='page-link'
                disabled={!nextPage}
                onClick={() => goToPage(currentPage + 1)}
              >
                <span aria-hidden='true'>&raquo;</span>
              </button>
            </li>
          </ul>
        </nav>
      </div>
    )
  }

  const PerPageSelector = () => (
    <select
      className='py-2 bg-transparent select-per-page px-2'
      value={perPage}
      onChange={handlePerPageChange}
    >
      {perPageOptions.map((option) => (
        <option key={option} value={option}>
          {option} par page
        </option>
      ))}
    </select>
  )
  /* Render
   *******************************************************************************************/
  return (
    <>
      {/* Filter component */}
      <Row>
        <Col className='col-3 bg-transparent mb-3'>
          <Form.Control
            type='text'
            placeholder='Rechercher par barcode'
            className='border-radius-0'
            value={barcode}
            onChange={handleBarcodeChange}
            onKeyDown={handleBarcodeSearch}
          />
        </Col>
        <Col className='col-3 bg-transparent mb-3'>
          <Form.Select
            aria-label='Default select example'
            className='form-control border-radius-0'
            onChange={(e) => handleStatusChange(e.target.value)}
            defaultValue={statusParam && statusParam}
          >
            <option value=''>-- Statut de la commande --</option>
            <option value='cancel'>Commande annulée</option>
            <option value='created'>Commande créée</option>
            <option value='ready_for_delivery'>Commande prête pour la livraison</option>
            <option value='picked_up'>Commande en cours de livraison</option>
            <option value='operin'>Commande déposée</option>
            <option value='receive'>Commande récupérée par le client</option>
            <option value='operout'>Commande récupérée par le coursier</option>
            <option value='reminder'>Commande non récupérée depuis un certain temps</option>
            <option value='overtimedue'>Commande proche de l'expiration</option>
            <option value='overtime'>Commande expirée</option>
            <option value='left_for_customer_service'>
              Commande retournée au service client
            </option>
            <option value='return'>Retour de la commande par le client</option>
            <option value=''>Tout afficher</option>
          </Form.Select>
        </Col>
        <Col>{loadSpinner && <Spinner className='mt-1' />}</Col>
      </Row>
      <Card>
        <>
          {loading ? (
            <Loading />
          ) : data?.length === 0 ? (
            <EmptyData />
          ) : (
            <>
              {/* List Table */}
              <Table striped hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Société</th>
                    <th>Machine</th>
                    <th>Barcode</th>
                    <th>Multi Order Code</th>
                    <th>Slot</th>
                    <th>Statut</th>
                    <th>Date de création</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((order: OrderType) => (
                    <tr key={order?.id}>
                      <td>
                        <Link to={'/livraisons/detail/' + order?.id}>{order?.id}</Link>
                      </td>
                      <td>
                        <Link to={'/livraisons/detail/' + order?.id}>
                          {order?.company?.name}
                        </Link>
                      </td>
                      <td>
                        <Link to={'/livraisons/detail/' + order?.id}>
                          {order?.locker?.location}
                          <br />
                          <small className='text-muted'>{order?.locker?.shortLocation}</small>
                        </Link>
                      </td>
                      <td>
                        <Link to={'/livraisons/detail/' + order?.id}>{order?.barcode}</Link>
                      </td>
                      <td>
                        <Link to={'/livraisons/detail/' + order?.id}>
                          {order?.multiOrderCode ? order?.multiOrderCode : '-'}
                        </Link>
                      </td>
                      <td>
                        {order?.status === 'operin' ||
                        order?.status === 'reminder' ||
                        order?.status === 'overtimedue' ||
                        order?.status === 'overtime' ? (
                          <Link to={'/livraisons/detail/' + order?.id}>
                            {order?.cleveronSlotId ? order?.cleveronSlotId : '-'}
                          </Link>
                        ) : (
                          '-'
                        )}
                      </td>
                      <td>
                        <Link to={'/livraisons/detail/' + order?.id}>
                          {setOrderStatus(order?.status)}
                        </Link>
                      </td>
                      <td>
                        <Link to={'/livraisons/detail/' + order?.id}>
                          {setDateTime(order?.createdAt)}
                        </Link>
                      </td>
                      <td>
                        <div className='dropdown no-arrow btn-actions'>
                          <button
                            className='btn btn-secondary dropdown-toggle '
                            type='button'
                            data-toggle='dropdown'
                            aria-expanded='false'
                          >
                            <i className='ri-more-2-fill ri-xl'></i>
                          </button>
                          <div className='dropdown-menu py-0'>
                            <Link
                              className='dropdown-item border-bottom py-3'
                              to={'/livraisons/detail/' + order?.id}
                            >
                              <i className='ri-list-check-2 ri-lg mr-2 align-middle'></i>
                              Afficher le détail
                            </Link>
                            {order?.status === 'picked_up' ? (
                              <>
                                <Link
                                  className='dropdown-item border-bottom py-3'
                                  to={'/livraisons/dupliquer/' + order?.id}
                                >
                                  <i className='ri-file-copy-2-line ri-lg mr-2 align-middle'></i>
                                  Dupliquer cette commande
                                </Link>
                                <span
                                  className='dropdown-item border-bottom py-3 cursor-pointer'
                                  onClick={() =>
                                    handleUpdateStatus(
                                      Number(order?.id),
                                      'left_for_customer_service'
                                    )
                                  }
                                >
                                  <i className='ri-arrow-go-back-line ri-lg mr-2 align-middle'></i>
                                  Commande retournée au service client
                                </span>
                                <span
                                  className='dropdown-item border-bottom py-3 cursor-pointer'
                                  onClick={() =>
                                    handleUpdateStatus(Number(order?.id), 'operin')
                                  }
                                >
                                  <i className='ri-inbox-archive-line ri-lg mr-2 align-middle'></i>
                                  Commande déposée dans la EasyBox
                                </span>
                                <span
                                  className='dropdown-item border-bottom py-3 cursor-pointer'
                                  onClick={() =>
                                    handleUpdateStatus(Number(order?.id), 'cancel')
                                  }
                                >
                                  <i className='ri-close-circle-line ri-lg mr-2 align-middle'></i>
                                  Annuler cette commande
                                </span>
                              </>
                            ) : null}
                            {order?.status === 'ready_for_delivery' ? (
                              <>
                                <Link
                                  className='dropdown-item border-bottom py-3'
                                  to={'/livraisons/dupliquer/' + order?.id}
                                >
                                  <i className='ri-file-copy-2-line ri-lg mr-2 align-middle'></i>
                                  Dupliquer cette commande
                                </Link>
                                <span
                                  className='dropdown-item border-bottom py-3 cursor-pointer'
                                  onClick={() =>
                                    handleUpdateStatus(Number(order?.id), 'picked_up')
                                  }
                                >
                                  <i className='ri-truck-line ri-lg mr-2 align-middle'></i>
                                  Commande en cours de livraison
                                </span>
                              </>
                            ) : null}
                            {order?.status === 'created' && (
                              <>
                                <Link
                                  className='dropdown-item border-bottom py-3'
                                  to={'/livraisons/dupliquer/' + order?.id}
                                >
                                  <i className='ri-file-copy-2-line ri-lg mr-2 align-middle'></i>
                                  Dupliquer cette commande
                                </Link>
                                <span
                                  className='dropdown-item border-bottom py-3 cursor-pointer'
                                  onClick={() =>
                                    handleUpdateStatus(Number(order?.id), 'ready_for_delivery')
                                  }
                                >
                                  <i className='ri-inbox-unarchive-line ri-lg mr-2 align-middle'></i>
                                  Commande prête pour la livraison
                                </span>
                              </>
                            )}
                            {order?.status === 'operout' && (
                              <>
                                <span
                                  className='dropdown-item border-bottom py-3 cursor-pointer'
                                  onClick={() =>
                                    handleUpdateStatus(
                                      Number(order?.id),
                                      'left_for_customer_service'
                                    )
                                  }
                                >
                                  <i className='ri-arrow-go-back-line ri-lg mr-2 align-middle'></i>
                                  Commande retournée au service client
                                </span>
                                <span
                                  className='dropdown-item border-bottom py-3 cursor-pointer'
                                  onClick={() =>
                                    handleUpdateStatus(Number(order?.id), 'cancel')
                                  }
                                >
                                  <i className='ri-close-circle-line ri-lg mr-2 align-middle'></i>
                                  Annuler cette commande
                                </span>
                              </>
                            )}
                            {order?.status === 'operin' && (
                              <>
                                <span
                                  className='dropdown-item border-bottom py-3 cursor-pointer'
                                  onClick={() =>
                                    handleSendNotification(Number(order?.id), 'sms')
                                  }
                                >
                                  <i className='ri-message-2-line ri-lg mr-2 align-middle'></i>
                                  Renvoyer le SMS
                                </span>
                                <span
                                  className='dropdown-item border-bottom py-3 cursor-pointer'
                                  onClick={() =>
                                    handleSendNotification(Number(order?.id), 'mail')
                                  }
                                >
                                  <i className='ri-mail-send-line ri-lg mr-2 align-middle'></i>
                                  Renvoyer l'email
                                </span>
                              </>
                            )}
                            {order?.status === 'operin' ||
                            order?.status === 'reminder' ||
                            order?.status === 'overtimedue' ||
                            order?.status === 'left_for_customer_service' ||
                            order?.status === 'overtime' ? (
                              <span
                                className='dropdown-item border-bottom py-3 cursor-pointer'
                                onClick={() =>
                                  handleUpdateStatus(Number(order?.id), 'receive')
                                }
                              >
                                <i className='ri-checkbox-line ri-lg mr-2 align-middle'></i>
                                Commande récupérée par le client
                              </span>
                            ) : null}
                            {order?.status === 'operin' ||
                            order?.status === 'reminder' ||
                            order?.status === 'overtimedue' ||
                            order?.status === 'overtime' ? (
                              <span
                                className='dropdown-item border-bottom py-3 cursor-pointer'
                                onClick={() =>
                                  handleUpdateStatus(Number(order?.id), 'operout')
                                }
                              >
                                <i className='ri-logout-box-line ri-lg mr-2 align-middle'></i>
                                Commande récupérée par le coursier
                              </span>
                            ) : null}
                            {order?.status === 'created' ||
                            order?.status === 'left_for_customer_service' ||
                            order?.status === 'ready_for_delivery' ? (
                              <span
                                className='dropdown-item border-bottom py-3 cursor-pointer'
                                onClick={() => handleUpdateStatus(Number(order?.id), 'cancel')}
                              >
                                <i className='ri-close-circle-line ri-lg mr-2 align-middle'></i>
                                Annuler cette commande
                              </span>
                            ) : (
                              order?.status === 'cancel' && (
                                <span
                                  className='dropdown-item border-bottom py-3 cursor-pointer'
                                  onClick={() => handleDelete(Number(order?.id))}
                                >
                                  <i className='ri-delete-bin-2-line ri-lg mr-2 align-middle'></i>
                                  Supprimer cette commande
                                </span>
                              )
                            )}
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Row className='ms-auto pe-5'>
                <Col className='col'>
                  <PaginationComponent />
                </Col>
                <Col className='col'>
                  <PerPageSelector />
                </Col>
              </Row>
            </>
          )}
        </>
      </Card>
    </>
  )
}
