import { ProductType } from '@/types/ProductType'
import { Plus, X } from 'lucide-react'
import React from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'

interface ProductManagerProps {
  initialProducts: ProductType[]
  setProducts: (products: ProductType[]) => void
  removeProduct: (productIndex: number) => void
}

export const ProductManager: React.FC<ProductManagerProps> = ({
  initialProducts,
  setProducts,
  removeProduct,
}) => {
  const [products, setLocalProducts] = React.useState<ProductType[]>(initialProducts)

  React.useEffect(() => {
    setLocalProducts(initialProducts)
  }, [initialProducts])

  const handleAddNewProductField = () => {
    setLocalProducts([...products, { name: '', quantity: 0, isAlcoholic: false, price: 1 }])
  }

  const handleRemoveProductLine = (productIndex: number) => {
    setLocalProducts((prevProducts) => prevProducts.filter((_, i) => i !== productIndex))
    removeProduct(productIndex)
  }

  const handleChangeProductName = (productIndex: number, value: string) => {
    const updatedProducts = [...products]
    updatedProducts[productIndex].name = value
    setLocalProducts(updatedProducts)
    setProducts(updatedProducts)
  }

  const handleChangeProductQty = (productIndex: number, value: number) => {
    const updatedProducts = [...products]
    updatedProducts[productIndex].quantity = value
    setLocalProducts(updatedProducts)
    setProducts(updatedProducts)
  }

  const handleChangeIsAlcoholic = (productIndex: number, checked: boolean) => {
    const updatedProducts = [...products]
    updatedProducts[productIndex].isAlcoholic = checked
    setLocalProducts(updatedProducts)
    setProducts(updatedProducts)
  }

  return (
    <>
      {products.map((product, productIndex) => (
        <Row key={productIndex} className='mb-3'>
          <Col>
            <Form.Control
              type='text'
              name='product_name'
              placeholder='Nom du produit'
              value={product.name}
              onChange={(e) => handleChangeProductName(productIndex, e.target.value)}
            />
          </Col>
          <Col className='col-2'>
            <Form.Control
              type='number'
              name='product_qty'
              placeholder='Qté.'
              value={product.quantity}
              onChange={(e) => handleChangeProductQty(productIndex, Number(e.target.value))}
            />
          </Col>
          <Col className='col-2 text-end pt-2'>
            <Form.Check
              type='checkbox'
              label='Alcool'
              checked={product.isAlcoholic}
              onChange={(e) => handleChangeIsAlcoholic(productIndex, e.target.checked)}
            />
          </Col>
          <Col className='col-2 text-end'>
            <Button
              variant='transparent'
              className='btn btn-sm'
              onClick={() => handleRemoveProductLine(productIndex)}
            >
              <X />
            </Button>
          </Col>
        </Row>
      ))}
      <Button variant='transparent text-success ps-0 mt-2' onClick={handleAddNewProductField}>
        <Plus size={16} /> Ajouter un nouveau produit
      </Button>
    </>
  )
}
