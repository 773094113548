/* eslint-disable react-hooks/exhaustive-deps */
import { ClientDetailCard } from '@/components/entities/client/ClientDetailCard'
import { OrderDetailCard } from '@/components/entities/order/OrderDetailCard'
import { ProductDetailCard } from '@/components/entities/product/ProductDetailCard'
import { ButtonLinkBack } from '@/components/lib/ButtonLinkBack'
import { Title } from '@/components/lib/Title'
import AuthService from '@/services/Auth/AuthService'
import CompanyService from '@/services/Company/CompanyService'
import OrderService from '@/services/Order/OrderService'
import UserService from '@/services/User/UserService'
// import UserService from '@/services/User/UserService'
import { BookingSlotType } from '@/types/BookingSlotType'
import { OrderType } from '@/types/OrderType'
import { UserType } from '@/types/UserType'
import {
  getMessageErrorSubmit,
  getOrderStatus,
  getOrderStatusTimeLine,
  setDateTime,
  setImageLocker,
  sortOrderHistory,
} from '@/utils/Functions'
import { flashStore } from '@/utils/Store'
import * as Sentry from '@sentry/react'
import { AxiosError, AxiosResponse } from 'axios'
import { Edit } from 'lucide-react'
import React from 'react'
import {
  Button,
  Card,
  Col,
  Form,
  Image,
  ListGroup,
  Modal,
  Row,
  Spinner,
} from 'react-bootstrap'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

interface ShipperInputForm {
  firstName: string
  lastName: string
  id: number
}

interface BookingInputForm {
  booking_id: number
}

interface ApiResponse {
  response: AxiosResponse
  violations: Violation[]
}

interface Violation {
  propertyPath: string
  message: string
  code: string
}

export const OrderDetail = () => {
  /* States / Hooks
   *******************************************************************************************/
  const param = useParams()
  const [data, setData] = React.useState<OrderType>()
  const [showModalFormDeliverer, setShowModalFormDeliverer] = React.useState<boolean>(false)
  const [shippers, setShippers] = React.useState<UserType[]>([])
  const { register: registerShipper, handleSubmit: handleSubmitShipper } =
    useForm<ShipperInputForm>()
  const { register: registerBooking, handleSubmit: handleSubmitBooking } =
    useForm<BookingInputForm>()
  const [messageErrorInput, setMessageErrorInput] = React.useState()
  const flash = flashStore()
  const [onSubmitLoading, setOnSubmitLoading] = React.useState<boolean>(false)
  const [AuthUser, setAuthUser] = React.useState<UserType>()
  const [bookingSlots, setBookingSlot] = React.useState<BookingSlotType[]>([])
  const [showModalFormTemperatureZone, setShowModalFormTemperatureZone] =
    React.useState<boolean>(false)

  /* useEffect
   *******************************************************************************************/
  React.useEffect(() => {
    getUserAuth(Number(param.id_order))
    // getData(Number(param.id_order))
  }, [param.id_order])

  /* Function / Events
   *******************************************************************************************/
  const getData = (orderId: number) => {
    if (AuthUser?.roles[0] === 'ROLE_SUPER_ADMIN' || AuthUser?.roles[0] === 'ROLE_ADMIN') {
      getDataForAdmin(orderId)
    } else {
      getDataForUser(orderId)
    }
  }

  const getDataForAdmin = (orderId: number) => {
    OrderService.getOrderByIdForAdmin(orderId)
      .then((response: AxiosResponse) => {
        setData(response.data)
        if (response.data?.history.length > 0) {
          sortOrderHistory(response.data?.history)
        }
      })
      .catch((error: AxiosError) => {
        flash.setMessageErrorFetch()
        Sentry.captureException(error)
      })
  }
  const getDataForUser = (orderId: number) => {
    OrderService.getOrderByIdForUser(orderId)
      .then((response: AxiosResponse) => {
        setData(response.data)
        if (response.data?.history.length > 0) {
          sortOrderHistory(response.data?.history)
        }
      })
      .catch((error: AxiosError) => {
        flash.setMessageErrorFetch()
        Sentry.captureException(error)
      })
  }

  const getUserAuth = (orderId: number) => {
    const storedUserAuth = localStorage.getItem(import.meta.env.VITE_APP_SESSION_USER)
    if (storedUserAuth) {
      const parsedUserData = JSON.parse(storedUserAuth)
      setAuthUser(parsedUserData)
      if (
        parsedUserData?.roles[0] === 'ROLE_SUPER_ADMIN' ||
        parsedUserData?.roles[0] === 'ROLE_ADMIN'
      ) {
        getDataForAdmin(orderId)
      } else {
        getDataForUser(orderId)
      }
    }
  }

  const handleCloseModalFormDeliverer = () => {
    setShowModalFormDeliverer(false)
  }

  const handleShowFormModalDeliverer = () => {
    UserService.getAllUser().then((response: AxiosResponse) => {
      const shippers = response.data['hydra:member'].filter((user: UserType) =>
        user.roles.includes('ROLE_SHIPPER')
      )
      setShippers(shippers)
    })
    setShowModalFormDeliverer(true)
  }

  // Assignement livreur
  const onSubmitShipper: SubmitHandler<ShipperInputForm> = (shipper) => {
    setOnSubmitLoading(true)
    const data = { shippedBy: 'api/users/' + shipper.id }
    OrderService.update(Number(param.id_order), data)
      .then(() => {
        flash.setMessage('success', 'Livreur assigné !')
      })
      .catch((error: ApiResponse) => {
        if (error?.response?.data?.violations?.length > 0) {
          flash.setMessageErrorValidator()
          setMessageErrorInput(error.response.data.violations)
        } else {
          flash.setMessageErrorForm()
          Sentry.captureException(error)
        }
      })
      .finally(() => {
        getData(Number(param.id_order))
        setOnSubmitLoading(false)
        setShowModalFormDeliverer(false)
      })
  }

  // Handle to change temperature zone
  const handleCloseModalFormTemperatureZone = () => {
    setShowModalFormTemperatureZone(false)
  }

  const handleShowModalFormTemperatureZone = () => {
    const company_id: number = Number(data?.company?.id)
    CompanyService.getBookingsByCompanyId(company_id, 1, 50)
      .then((response: AxiosResponse) => {
        setBookingSlot(response.data['hydra:member'])
      })
      .catch((error: AxiosError) => {
        AuthService.refreshToken()
        Sentry.captureException(error)
      })
      .finally(() => {
        setShowModalFormTemperatureZone(true)
      })
  }

  const onSubmitBooking: SubmitHandler<BookingInputForm> = (data) => {
    const order_id: number = Number(param?.id_order)
    const bookingSlot = { bookingSlot: '/api/booking_slots/' + data?.booking_id }
    setOnSubmitLoading(true)

    OrderService.update(order_id, bookingSlot)
      .then(() => {
        flash.setMessage('success', 'La zone de température a bien été changée.')
      })
      .catch((error: ApiResponse) => {
        if (error?.response?.data?.violations?.length > 0) {
          flash.setMessageErrorValidator()
          setMessageErrorInput(error.response.data.violations)
        } else {
          flash.setMessageErrorForm()
          Sentry.captureException(error)
        }
      })
      .finally(() => {
        getData(Number(param.id_order))
        setOnSubmitLoading(false)
        setShowModalFormTemperatureZone(false)
      })
  }

  /* Render
   *******************************************************************************************/
  return (
    <>
      <Row>
        <Col>
          <Title name={'Détail de la livraison #' + param.id_order} />
          <span>Créer le {data?.createdAt && setDateTime(data?.createdAt)}</span>
        </Col>
        <Col>
          <ButtonLinkBack />
        </Col>
      </Row>
      {/* Summary */}
      <Row className='mt-4'>
        <Col lg={4} sm={12}>
          <Card>
            <Card.Body className='d-flex'>
              <div className='flex-shrink-0 avatar-sm me-3'>
                <div className='avatar-title text--primary fs-22 rounded'>
                  <i className='ri-list-check-2 ri-2x'></i>
                </div>
              </div>
              <div className='flex-grow-1'>
                <h5 className='mb-0'>Statut de la commande</h5>
                <h6 className='mb-0 mt-2 ms-1'>{getOrderStatus(data?.status)}</h6>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={4} sm={12}>
          <Card>
            <Card.Body className='d-flex'>
              <div className='flex-shrink-0 avatar-sm me-3'>
                <div className='avatar-title text--primary fs-22 rounded'>
                  <i className='ri-map-pin-line ri-2x'></i>
                </div>
              </div>
              <div className='flex-grow-1'>
                <h5 className='mb-0'>Adresse de la machine</h5>
                <h6 className='mb-0 ms-1 mt-2'>
                  {data?.locker?.location}
                  <br />
                  <small>{data?.locker?.shortLocation}</small>
                </h6>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={4} sm={12}>
          <Card>
            <Card.Body className='d-flex'>
              <div className='flex-shrink-0 avatar-sm me-3'>
                <div className='avatar-title text--primary fs-22 rounded'>
                  <i className='ri-shopping-basket-2-line ri-2x'></i>
                </div>
              </div>
              <div className='flex-grow-1'>
                <Edit
                  size={18}
                  onClick={handleShowModalFormTemperatureZone}
                  className='ms-3 text-danger cursor-pointer float-end'
                />
                <h5 className='mb-0'>Zone de température</h5>
                <h6 className='mb-0 ms-1 mt-2'>
                  {data?.bookingSlot?.slot?.temperatureZone?.myKey} -{' '}
                  {data?.bookingSlot?.slot?.temperatureZone?.name}
                </h6>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className='mt-4'>
        <Col lg={8}>
          <Card className='mb-4'>
            <Card.Header>
              <h5 className='mt-2'>Historique de la commande</h5>
            </Card.Header>
            <Card.Body className='py-3 px-5'>
              <ul className='timeline'>
                {data?.history !== undefined && data?.history?.length > 0 ? (
                  data?.history
                    .reverse()
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    .map((h: any, i: number) => (
                      <li key={i} className={i === history.length - 1 ? 'active' : ''}>
                        <h6 className='mb-0 text--primary fw-bold'>
                          {getOrderStatusTimeLine(h?.status)}
                        </h6>
                        <>
                          <p className='text-muted mb-0'>{setDateTime(h?.createdAt)}</p>
                        </>
                      </li>
                    ))
                ) : (
                  <li>
                    <h6 className='mb-0 text--primary fw-bold'>Commande créée</h6>
                    <p className='text-muted mb-0'>
                      {data?.createdAt && setDateTime(data?.createdAt)}
                    </p>
                  </li>
                )}
              </ul>
            </Card.Body>
          </Card>
          {/* <Card className='mb-4'>
            <Card.Header>
              <h5 className='mt-2'>Détails supplémentaires</h5>
            </Card.Header>
            <Card.Body className='p-0'>
              <Table>
                <thead>
                  <tr>
                    <th>Slot</th>
                    <th>Barcode</th>
                    <th>Multi Order Code</th>
                    <th>Receive Code</th>
                    <th>Hold Time</th>
                    <th>Livreur</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{data?.cleveronSlotId}</td>
                    <td>{data?.barcode}</td>
                    <td>{data?.multiOrderCode}</td>
                    <td>{data?.receiveCode}</td>
                    <td>{data?.hold}</td>
                    <td>
                      {data?.shippedBy?.firstName !== undefined ? (
                        <>
                          {data?.shippedBy?.firstName + ' ' + data?.shippedBy?.lastName}
                          <Edit
                            size={18}
                            className='ms-3 text-danger cursor-pointer'
                            onClick={handleShowFormModalDeliverer}
                          />
                        </>
                      ) : (
                        <>
                          <span>Aucun livreur assigné</span>
                          <Edit
                            size={18}
                            className='ms-3 text-danger cursor-pointer'
                            onClick={handleShowFormModalDeliverer}
                          />
                        </>
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card> */}
          <OrderDetailCard order={data} />
        </Col>
        <Col lg={4}>
          <ClientDetailCard client={data?.client} />
          <ProductDetailCard order={data} />
          {/* <Card className='mb-4'>
            <Card.Header>
              <h5 className='mt-2'>Produits de la commande</h5>
            </Card.Header>
            <Card.Body className='p-1'>
              <ListGroup as='ul' variant='flush'>
                {data?.products !== undefined &&
                  data?.products?.length > 0 &&
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  data?.products.map((product: any, index: number) => (
                    <ListGroup.Item as='li' key={index} className='py-3'>
                      {product.quantity} x {product.name}
                    </ListGroup.Item>
                  ))}
              </ListGroup>
            </Card.Body>
          </Card> */}
          <Card className='mb-4'>
            <Card.Header>
              <h5 className='mt-2'>Adresse de livraison</h5>
            </Card.Header>
            <Card.Body className='p-3'>
              <Row>
                <Col lg={5}>
                  {data?.locker?.type !== undefined && (
                    <Image
                      src={setImageLocker(data?.locker?.type)}
                      alt={'Locker'}
                      className='img-thumbnail mb-3'
                    />
                  )}
                </Col>
                <Col lg={7}>
                  <ListGroup as='ul' variant='flush'>
                    <ListGroup.Item as='li' className='py-2'>
                      Livreur :{' '}
                      {data?.shippedBy?.firstName !== undefined ? (
                        <>
                          <Edit
                            size={18}
                            className='ms-3 text-danger cursor-pointer float-end'
                            onClick={handleShowFormModalDeliverer}
                          />
                          {data?.shippedBy?.firstName + ' ' + data?.shippedBy?.lastName}
                        </>
                      ) : (
                        <>
                          <Edit
                            size={18}
                            className='ms-3 text-danger cursor-pointer float-end'
                            onClick={handleShowFormModalDeliverer}
                          />
                          <span>Aucun livreur assigné</span>
                        </>
                      )}
                    </ListGroup.Item>
                    <ListGroup.Item as='li' className='py-2'>
                      Destination : {data?.locker?.location}
                      <br />
                      {data?.locker?.shortLocation}
                    </ListGroup.Item>
                    <ListGroup.Item as='li' className='py-2'>
                      Commune : {data?.locker?.city}
                    </ListGroup.Item>
                  </ListGroup>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          {/* <Card className="mb-4 text-center">
            <Card.Header>
              <h5 className="mt-2">QRCode retrait</h5>
            </Card.Header>
            <Card.Body className="p-3">
              <QRCode value={data.receiveCode} />
              <br />
              <br />
              <small>Saisie maunelle : {data.receiveCode}</small>
            </Card.Body>
          </Card> */}
        </Col>
      </Row>
      {/* Modal Form Deliverer > Assign deliverer */}
      <Modal show={showModalFormDeliverer} onHide={handleCloseModalFormDeliverer}>
        <Modal.Header closeButton>
          <Modal.Title>Assigner un livreur</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmitShipper(onSubmitShipper)}>
          <Modal.Body>
            <Form.Select
              aria-label='Default select example'
              className='form-control'
              {...registerShipper('id')}
            >
              <option>-- Sélectionnez un livreur --</option>
              {shippers?.map((shipper: UserType) => (
                <option value={shipper?.id} key={shipper?.id}>
                  {shipper?.firstName + ' ' + shipper?.lastName}
                </option>
              ))}
            </Form.Select>
            {messageErrorInput && (
              <small className='text-danger'>
                {getMessageErrorSubmit(messageErrorInput, 'id')}
              </small>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleCloseModalFormDeliverer}>
              Annuler
            </Button>
            <Button type='submit' variant='dark'>
              {onSubmitLoading ? <Spinner size={'sm'} /> : 'Enregistrer'}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* Modal Form TemperatureZone > Change zone */}
      <Modal show={showModalFormTemperatureZone} onHide={handleCloseModalFormTemperatureZone}>
        <Modal.Header closeButton>
          <Modal.Title>Changer de zone de température</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmitBooking(onSubmitBooking)}>
          <Modal.Body>
            <Form.Select
              aria-label='Default select example'
              className='form-control'
              {...registerBooking('booking_id')}
            >
              <option>-- Sélectionnez une zone de température --</option>
              {bookingSlots?.map(
                (bookingSlot: BookingSlotType) =>
                  bookingSlot?.available !== 0 && (
                    <option value={bookingSlot?.id} key={bookingSlot?.id}>
                      {bookingSlot?.slot?.temperatureZone?.locker?.shortLocation} -{' '}
                      {bookingSlot?.slot?.temperatureZone?.name} - H{bookingSlot?.slot?.height}{' '}
                      x L{bookingSlot?.slot?.width} x P{bookingSlot?.slot?.depth}
                    </option>
                  )
              )}
            </Form.Select>
            {messageErrorInput && (
              <small className='text-danger'>
                {getMessageErrorSubmit(messageErrorInput, 'id')}
              </small>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleCloseModalFormTemperatureZone}>
              Annuler
            </Button>
            <Button type='submit' variant='dark'>
              {onSubmitLoading ? <Spinner size={'sm'} /> : 'Enregistrer'}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}
