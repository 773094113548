import OrderService from '@/services/Order/OrderService'
import { OrderType } from '@/types/OrderType'
import { getMessageErrorSubmit } from '@/utils/Functions'
import { flashStore } from '@/utils/Store'
import * as Sentry from '@sentry/react'
import { AxiosResponse } from 'axios'
import { Edit } from 'lucide-react'
import React, { useState } from 'react'
import { Button, Card, Form, Spinner, Table } from 'react-bootstrap'

interface ApiResponse {
  response: AxiosResponse
  violations: Violation[]
}

interface Violation {
  propertyPath: string
  message: string
  code: string
}

type Props = {
  order: OrderType | undefined
}

// type Input = {
//   barcode: string
//   cleveronSlotId: string
//   multiOrderCode: string
//   receiveCode: string
//   hold: string
// }

export const OrderDetailCard: React.FC<Props> = ({ order }: Props) => {
  /* States
   *******************************************************************************************/
  const [onSubmitLoading, setOnSubmitLoading] = React.useState<boolean>(false)
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [messageErrorInput, setMessageErrorInput] = React.useState()
  const [data, setData] = useState<OrderType | undefined>(order)
  const flash = flashStore()

  /* UseEffect
   *******************************************************************************************/
  React.useEffect(() => {
    setData(order)
  }, [order])

  /* Functions
   *******************************************************************************************/
  // Function to handle input changes in the form fields
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setData((prevData) => {
      if (!prevData) return prevData
      return { ...prevData, [name]: name === 'hold' ? Number(value) : value }
    })
  }

  // Function to handle form submission
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setOnSubmitLoading(true)
    if (data?.id !== undefined) {
      OrderService.update(data.id, {
        barcode: data?.barcode,
        cleveronSlotId: Number(data?.cleveronSlotId),
        multiOrderCode: data?.multiOrderCode,
        receiveCode: data?.receiveCode,
        hold: data?.hold,
        ageRestriction: data?.ageRestriction ? Number(data?.ageRestriction) : null,
      })
        .then(() => {
          flash.setMessage('success', 'Les données ont bien été modifiées !')
          setIsEditing(false)
          setOnSubmitLoading(false)
        })
        .catch((error: ApiResponse) => {
          setOnSubmitLoading(false)
          if (error?.response?.data?.violations?.length > 0) {
            flash.setMessageErrorValidator()
            setMessageErrorInput(error.response.data.violations)
          } else {
            flash.setMessageErrorForm()
            Sentry.captureException(error)
          }
        })
    }
  }

  /* Return
   *******************************************************************************************/
  return (
    <Card className='mb-4'>
      <Card.Header className='d-flex justify-content-between align-items-center'>
        <h5 className='mt-2'>Détails supplémentaires</h5>
        <Edit className='cursor-pointer' onClick={() => setIsEditing(true)} />
      </Card.Header>
      <Card.Body className='p-0'>
        {!isEditing ? (
          <Table>
            <thead>
              <tr>
                <th>Slot</th>
                <th>Barcode</th>
                <th>Multi Order Code</th>
                <th>Receive Code</th>
                <th>Hold Time</th>
                <th>Age restriction</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{data?.cleveronSlotId}</td>
                <td>{data?.barcode}</td>
                <td>{data?.multiOrderCode}</td>
                <td>{data?.receiveCode}</td>
                <td>{data?.hold}</td>
                <td>{data?.ageRestriction ?? '-'}</td>
              </tr>
            </tbody>
          </Table>
        ) : (
          <Form onSubmit={handleSubmit}>
            <Table>
              <thead>
                <tr>
                  <th>Slot</th>
                  <th>Barcode</th>
                  <th>Multi Order Code</th>
                  <th>Receive Code</th>
                  <th>Hold Time</th>
                  <th>Age restriction</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Form.Control
                      type='text'
                      name='cleveronSlotId'
                      value={data?.cleveronSlotId || ''}
                      onChange={handleInputChange}
                    />
                    {messageErrorInput && (
                      <small className='text-danger'>
                        {getMessageErrorSubmit(messageErrorInput, 'cleveronSlotId')}
                      </small>
                    )}
                  </td>
                  <td>
                    <Form.Control
                      type='text'
                      name='barcode'
                      value={data?.barcode || ''}
                      onChange={handleInputChange}
                    />
                    {messageErrorInput && (
                      <small className='text-danger'>
                        {getMessageErrorSubmit(messageErrorInput, 'barcode')}
                      </small>
                    )}
                  </td>
                  <td>
                    <Form.Control
                      type='text'
                      name='multiOrderCode'
                      value={data?.multiOrderCode || ''}
                      onChange={handleInputChange}
                    />
                    {messageErrorInput && (
                      <small className='text-danger'>
                        {getMessageErrorSubmit(messageErrorInput, 'multiOrderCode')}
                      </small>
                    )}
                  </td>
                  <td>
                    <Form.Control
                      type='text'
                      name='receiveCode'
                      value={data?.receiveCode || ''}
                      onChange={handleInputChange}
                    />
                    {messageErrorInput && (
                      <small className='text-danger'>
                        {getMessageErrorSubmit(messageErrorInput, 'receiveCode')}
                      </small>
                    )}
                  </td>
                  <td>
                    <Form.Control
                      type='text'
                      name='hold'
                      value={data?.hold || ''}
                      onChange={handleInputChange}
                    />
                    {messageErrorInput && (
                      <small className='text-danger'>
                        {getMessageErrorSubmit(messageErrorInput, 'hold')}
                      </small>
                    )}
                  </td>
                  <td>
                    <Form.Control
                      type='text'
                      name='ageRestriction'
                      value={data?.ageRestriction || ''}
                      onChange={handleInputChange}
                    />
                    {messageErrorInput && (
                      <small className='text-danger'>
                        {getMessageErrorSubmit(messageErrorInput, 'ageRestriction')}
                      </small>
                    )}
                  </td>
                </tr>
              </tbody>
            </Table>
            <div className='d-flex justify-content-end mt-3 pb-4 pe-4'>
              <Button
                variant='secondary'
                type='submit'
                className='me-3'
                onClick={() => setIsEditing(false)}
              >
                Annuler
              </Button>
              <Button variant='dark' type='submit'>
                {onSubmitLoading ? <Spinner size='sm' /> : 'Enregistrer'}
              </Button>
            </div>
          </Form>
        )}
      </Card.Body>
    </Card>
  )
}
