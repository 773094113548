import { ClientType } from '@/types/ClientType'
import axios from 'axios'
import authHeader from '../Auth/AuthHeader'

const API_URL = import.meta.env.VITE_APP_END_POINT

class ClientService {
  update(id: number, data: ClientType) {
    axios.defaults.headers.patch['Content-Type'] = 'application/merge-patch+json'
    return axios.patch(API_URL + 'clients/' + id, data, {
      headers: authHeader(),
    })
  }

  _delete = async (id: number) => {
    return await axios.delete(API_URL + `clients/${id}`, {
      headers: authHeader(),
    })
  }
}

export default new ClientService()
