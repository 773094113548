import { ProductType } from '@/types/ProductType'
import axios from 'axios'
import authHeader from '../Auth/AuthHeader'

const API_URL = import.meta.env.VITE_APP_END_POINT

interface OrderCreate {
  barcode?: string
  receiveCode?: string
  multiOrderCode?: string
  ageRestriction?: number | string | null
  bookingSlot: string
  client?: {
    email: string
    firstname: string
    lastname: string
    phone: string
  }
  hold?: number
  products: ProductType[]
  externalOrderId?: string
}

class OrderService {
  getOrders(page: number, perPage: number, barcode?: string, status?: string) {
    const barcodeParam = barcode ? '&barcode=' + barcode : ''
    const statusParam = status ? '&status=' + status : ''

    return axios.get(
      API_URL +
        'orders?page=' +
        page +
        '&itemsPerPage=' +
        perPage +
        '&q[company][]=id&q[company][]=name&q[locker][]=location&q[locker][]=shortLocation&q[]=cleveronSlotId&q[]=multiOrderCode&q[]=barcode&q[]=ageRestriction&q[]=status&q[]=id&q[]=createdAt&order[id]=DESC' +
        barcodeParam +
        statusParam,
      { headers: authHeader() }
    )
  }

  getOrdersByBarcodePerPage(barcode: string, page: number, perPage: number) {
    return axios.get(
      API_URL +
        'orders?page=' +
        page +
        '&itemsPerPage=' +
        perPage +
        '&q[company][]=id&q[company][]=name&q[locker][]=location&q[locker][]=shortLocation&q[]=cleveronSlotId&q[]=multiOrderCode&q[]=barcode&q[]=ageRestriction&q[]=status&q[]=id&q[]=createdAt&order[id]=DESC&barcode=' +
        barcode,
      { headers: authHeader() }
    )
  }

  getOrdersByStatusPerPage(status: string, page: number, perPage: number) {
    return axios.get(
      API_URL +
        'orders?status=' +
        status +
        '&page=' +
        page +
        '&itemsPerPage=' +
        perPage +
        '&q[company][]=name&q[locker][]=location&q[locker][]=shortLocation&q[]=barcode&q[]=ageRestriction&q[]=cleveronSlotId&q[]=status&q[]=id&q[]=createdAt&order[id]=DESC',
      { headers: authHeader() }
    )
  }

  getOrdersByCompanyPerPage(cleveronCompanyId: string, page: number, perPage: number) {
    return axios.get(
      API_URL +
        'orders?bookingSlot.company.cleveronCompanyId=' +
        cleveronCompanyId +
        '&page=' +
        page +
        '&itemsPerPage=' +
        perPage +
        '&q[company][]=name&q[locker][]=location&q[locker][]=shortLocation&q[]=cleveronSlotId&q[]=barcode&q[]=ageRestriction&q[]=status&q[]=id&q[]=createdAt&order[id]=DESC',
      { headers: authHeader() }
    )
  }

  getOrderLoadNextPage(uri: string | undefined) {
    const new_uri = uri?.replace('/api/', '')
    return axios.get(API_URL + new_uri, { headers: authHeader() })
  }

  // Show client info with admin and super admin
  getOrderByIdForAdmin(id: number) {
    return axios.get(
      API_URL +
        'orders/' +
        id +
        '?q[]=id&q[]=receiveCode&q[]=multiOrderCode&q[]=hold&q[company][]=id&q[history][]=createdAt&q[history][]=status&q[]=products&q[]=cleveronSlotId&q[]=status&q[locker][]=location&q[bookingSlot][slot][temperatureZone][]=myKey&q[bookingSlot][slot][temperatureZone][]=name&q[]=updatedAt&q[]=history&q[]=createdAt&q[]=barcode&q[]=ageRestriction&q[]=externalOrderId&q[]=multiOrderCode&q[]=receiveCode&q[shippedBy][]=firstName&q[shippedBy][]=lastName&q[client][]=id&q[client][]=lastname&q[client][]=firstname&q[client][]=phone&q[client][]=email&q[locker][]=type&q[locker][]=city&q[locker][]=shortLocation',
      {
        headers: authHeader(),
      }
    )
  }

  // Don't show client info
  getOrderByIdForUser(id: number) {
    return axios.get(
      API_URL +
        'orders/' +
        id +
        '?q[]=id&q[]=multiOrderCode&q[]=hold&q[company][]=id&q[history][]=createdAt&q[history][]=status&q[]=products&q[]=cleveronSlotId&q[]=status&q[locker][]=location&q[bookingSlot][slot][temperatureZone][]=myKey&q[bookingSlot][slot][temperatureZone][]=name&q[]=updatedAt&q[]=history&q[]=createdAt&q[]=barcode&q[]=ageRestriction&q[]=receiveCode&q[shippedBy][]=firstName&q[shippedBy][]=lastName&q[locker][]=type&q[locker][]=city&q[locker][]=shortLocation',
      {
        headers: authHeader(),
      }
    )
  }

  create(data: OrderCreate) {
    return axios.post(API_URL + 'orders', data, { headers: authHeader() })
  }

  updateStatus(id: number, status: string) {
    axios.defaults.headers.patch['Content-Type'] = 'application/merge-patch+json'
    return axios.patch(
      API_URL + 'orders/' + id,
      { status: status },
      {
        headers: authHeader(),
      }
    )
  }

  update(id: number, data: object) {
    axios.defaults.headers.patch['Content-Type'] = 'application/merge-patch+json'
    return axios.patch(API_URL + 'orders/' + id, data, {
      headers: authHeader(),
    })
  }

  resendNotification(id: number, provider: string) {
    return axios.get(API_URL + `orders/${id}/resend-notification?${provider}`, {
      headers: authHeader(),
    })
  }

  _delete = async (id: number) => {
    return await axios.delete(API_URL + `orders/${id}`, {
      headers: authHeader(),
    })
  }
}

export default new OrderService()
